.ui.accordion .title:not(.ui),
.ui.button,
.ui.card > .content > .header,
.ui.text.container,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="url"],
.ui.header,
.ui.input input,
.ui.items > .item > .content > .header,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.menu,
.ui.message .header,
.ui.modal > .header,
.ui.popup > .header,
.ui.search > .results .result .title,
.ui.search > .results > .message .header,
.ui.category.search > .results .category > .name,
.ui.statistics .statistic > .value,
.ui.statistic > .value,
.ui.statistics .statistic > .label,
.ui.statistic > .label,
.ui.steps .step .title {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif !important; }

.ui.form {
  font-size: 1rem; }

@media only screen and (max-width: 767px) {
  .ui.container > .ui.stackable.grid > .column {
    padding-left: 1em !important;
    padding-right: 1em !important; } }

.ui.grid {
  margin-bottom: 0em !important; }

.ui.form .column {
  margin-bottom: -1em; }

.ui.form .error.message,
.ui.form .warning.message,
.ui.form .success.message {
  display: block; }

.ui.form .ui.grid {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  .ui.form .ui.grid .column.field {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.ui.form .field label.infield {
  position: relative;
  top: 10px;
  left: 15px;
  min-height: 20px;
  pointer-events: none;
  z-index: 1;
  text-align: left;
  font-size: 0.9em; }
  .ui.form .field label.infield + input,
  .ui.form .field label.infield + textarea,
  .ui.form .field label.infield + select,
  .ui.form .field label.infield + .ui.fake.input,
  .ui.form .field label.infield + .ui.dropdown {
    padding-top: 30px;
    margin-top: -25px;
    min-height: 58.5px;
    z-index: 0; }
  .ui.form .field label.infield + .ui.dropdown .icon {
    margin-top: 2px; }

.ui.transparent.button {
  background-color: transparent;
  border: none; }
  .ui.transparent.button:hover {
    background-color: rgba(0, 0, 0, 0.05); }

.ui.narrow.container,
.ui.narrow.text.container {
  max-width: 24em !important; }

.square {
  border-radius: 0 !important; }

.bold {
  font-weight: bold; }

.top-padded {
  margin: 2em auto 0 !important; }

.bottom-padded {
  margin: 0 auto 2em !important; }

.jumbotron {
  margin-bottom: 2em !important;
  border: 0 !important; }
  .jumbotron.segment {
    background: #f4f4f4 !important;
    text-align: center; }
    .jumbotron.segment .container {
      padding: 2em 3em; }
      @media only screen and (max-width: 767px) {
        .jumbotron.segment .container {
          padding: 2em 0.5em; } }
  .jumbotron h1.ui.header {
    font-size: 2.5em;
    font-weight: normal; }
  .jumbotron h2 {
    font-size: 1.3em;
    font-weight: normal; }

.ui.infield.label {
  position: absolute;
  background: #fff;
  top: -20px;
  z-index: 20; }
  .ui.infield.label.left.aligned {
    left: 6px; }
  .ui.infield.label.right.aligned {
    right: 6px; }

.ui.button:last-child {
  margin-right: 0; }

@media only screen and (min-width: 480px) {
  [class*='mobile only'] {
    display: none; } }

@media only screen and (max-width: 480px) {
  [class*='computer only'] {
    display: none; } }

html, body {
  height: 100%; }

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff; }

/* Open Sans 400 */
/* cyrillic */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* Open Sans 600 */
/* cyrillic */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* Open Sans 400 italic */
/* cyrillic */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjUj_cnvWIuuBMVgbX098Mw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBiYE0-AqJ3nfInTTiDXDjU4.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

html {
  font-size: 14px; }

body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87); }

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.2857em;
  margin: calc(2rem - 0.14285em) 0em 1rem;
  font-weight: bold;
  padding: 0em; }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    margin-top: 0em; }
  h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child {
    margin-bottom: 0em; }

h1 {
  min-height: 1rem;
  font-size: 2rem; }

h2 {
  font-size: 1.714rem; }

h3 {
  font-size: 1.28rem; }

h4 {
  font-size: 1.071rem; }

h5 {
  font-size: 1rem; }

p {
  margin: 0em 0em 1em;
  line-height: 1.4285em; }
  p:first-child {
    margin-top: 0em; }
  p:last-child {
    margin-bottom: 0em; }

a {
  color: #0183d4;
  text-decoration: none;
  cursor: pointer; }
  a:hover {
    color: #0183d4;
    text-decoration: underline; }

::-webkit-selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87); }

::-moz-selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87); }

::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87); }

textarea::-webkit-selection,
input::-webkit-selection {
  background-color: rgba(100, 100, 100, 0.4);
  color: rgba(0, 0, 0, 0.87); }

textarea::-moz-selection,
input::-moz-selection {
  background-color: rgba(100, 100, 100, 0.4);
  color: rgba(0, 0, 0, 0.87); }

textarea::selection,
input::selection {
  background-color: rgba(100, 100, 100, 0.4);
  color: rgba(0, 0, 0, 0.87); }

.flex {
  display: flex; }

.flex-grow {
  flex-grow: 1; }

.flex.flex-align-start {
  align-items: flex-start; }

.flex.flex-align-center {
  align-items: center; }

.flex.flex-align-end {
  align-items: flex-end; }

.flex.flex-align-baseline {
  align-items: baseline; }

.flex > .flex-align-start {
  align-self: flex-start; }

.flex > .flex-align-center {
  align-self: center; }

.flex > .flex-align-end {
  align-self: flex-end; }

.flex > .flex-align-baseline {
  align-self: baseline; }

.m-0 {
  margin: 0rem !important; }

.m-0-neg {
  margin: 0rem !important; }

.ml-0 {
  margin-left: 0rem !important; }

.mr-0 {
  margin-right: 0rem !important; }

.mt-0 {
  margin-top: 0rem !important; }

.mb-0 {
  margin-bottom: 0rem !important; }

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important; }

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.m-1 {
  margin: 0.5rem !important; }

.m-1-neg {
  margin: -0.5rem !important; }

.ml-1 {
  margin-left: 0.5rem !important; }

.mr-1 {
  margin-right: 0.5rem !important; }

.mt-1 {
  margin-top: 0.5rem !important; }

.mb-1 {
  margin-bottom: 0.5rem !important; }

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important; }

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.m-2-neg {
  margin: -1rem !important; }

.ml-2 {
  margin-left: 1rem !important; }

.mr-2 {
  margin-right: 1rem !important; }

.mt-2 {
  margin-top: 1rem !important; }

.mb-2 {
  margin-bottom: 1rem !important; }

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.m-3-neg {
  margin: -1.5rem !important; }

.ml-3 {
  margin-left: 1.5rem !important; }

.mr-3 {
  margin-right: 1.5rem !important; }

.mt-3 {
  margin-top: 1.5rem !important; }

.mb-3 {
  margin-bottom: 1.5rem !important; }

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-4 {
  margin: 2rem !important; }

.m-4-neg {
  margin: -2rem !important; }

.ml-4 {
  margin-left: 2rem !important; }

.mr-4 {
  margin-right: 2rem !important; }

.mt-4 {
  margin-top: 2rem !important; }

.mb-4 {
  margin-bottom: 2rem !important; }

.mx-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.m-5 {
  margin: 2.5rem !important; }

.m-5-neg {
  margin: -2.5rem !important; }

.ml-5 {
  margin-left: 2.5rem !important; }

.mr-5 {
  margin-right: 2.5rem !important; }

.mt-5 {
  margin-top: 2.5rem !important; }

.mb-5 {
  margin-bottom: 2.5rem !important; }

.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.text-bold {
  font-weight: bold; }

.text-italic {
  font-style: italic; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-small {
  font-size: 75%; }

.text-big {
  font-size: 125%; }

.Layout__container {
  margin: 0 auto;
  max-width: 1280px; }

.Layout__Y-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  z-index: 1; }

.Layout__Y-main {
  position: absolute;
  left: 0;
  right: 0;
  top: 3rem;
  bottom: 0; }
  .Layout__Y-main .Layout__X-side {
    overflow: auto; }
  .Layout__Y-main .Layout__X-main {
    overflow: auto;
    overflow-y: scroll; }

.Layout__X-side {
  position: absolute;
  left: 0;
  width: 14rem;
  top: 0;
  bottom: 0; }

.Layout__X-main {
  position: absolute;
  left: 14rem;
  right: 0;
  top: 0;
  bottom: 0; }

.Navbar {
  background-color: #000;
  color: #eee; }

.Navbar__item {
  color: #eee; }
  .Navbar__item:hover, .Navbar__item:active {
    color: #fff;
    text-decoration: none; }

.Navbar__brand {
  font-weight: bold; }

.Sidebar {
  background-color: #f4f4f4; }

.Sidebar__item {
  display: block;
  padding: 0.6rem 1rem;
  width: 100%;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  color: #000;
  cursor: pointer; }
  .Sidebar__item:hover {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #000; }

.Sidebar__item--active {
  font-weight: bold; }
